import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import * as AcfLayout from "../components/Acf";
import Layout from "../components/Layout";
import { PageHeader } from "../components/PageHeader";
import ProjectList from "../components/Projects/ProjectList";
import { decodeEntities } from "../utils/helpers";
import loadable from "@loadable/component";

const loadableComponents = ["TestimonialSlider"];

const AcfComponent = ({
  location,
  componentName,
  item,
  pageContext,
  index,
}) => {
  // If component does not exist in acf folder, print error message
  const ComponentName = loadableComponents.includes(componentName)
    ? loadable((props) =>
        import(`../components/Acf/${componentName}/index.jsx`)
      )
    : AcfLayout[componentName];

  const componentTitle = item.__typename
    ? item.__typename.replace("WordPressAcf_", "")
    : "No name";
  if (!ComponentName) {
    return (
      <section className="page missing">
        <div className="inner">
          The <strong>"{componentTitle}"</strong> component is missing.{" "}
          <span>&#128540;</span>
        </div>
      </section>
    );
  } else {
    return (
      <ComponentName
        index={index}
        location={location}
        pageContext={pageContext}
        {...item}
      />
    );
  }
};

const ProjectIndexPage = (props) => {
  const { data, pageContext, location, path } = props;
  const {
    wordpressPost: page = [],
    allWordpressWpProjects,
    categoryFilter,
    categories,
    wordpressCategory,
    wordpressWpSettings,
    siteSettings,
  } = data;
  const { options = null } = siteSettings;
  const { wordpressUrl } = wordpressWpSettings;
  const { title, yoast, acf = {} } = page;
  const { layout } = acf || {
    layout: null,
  };

  const { title: siteTitle } = wordpressWpSettings;
  const { edges: posts } = allWordpressWpProjects;
  const featuredImage =
    wordpressCategory && wordpressCategory.acf.featureImage
      ? wordpressCategory.acf.featureImage
      : "";
  return (
    <Layout location={location} wordpressUrl={wordpressUrl} theme="grey">
      <SEO
        title={`${
          yoast.metaTitle
            ? yoast.metaTitle
            : `Projects | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
        yoast={yoast}
        location={location}
      />

      {layout &&
        layout.map((item, index) => {
          if (!item.__typename) return null;
          const layoutComponentName = item.__typename.replace(
            "WordPressAcf_",
            ""
          );
          if (item.__typename === "WordPressAcf_TopContentAndImage")
            return (
              <AcfComponent
                key={index}
                index={index}
                componentName={layoutComponentName}
                item={item}
                location={location}
                pageContext={pageContext}
              />
            );
        })}
      <ProjectList
        posts={posts}
        title="Latest projects"
        pageContext={pageContext}
        siteMetadata={wordpressWpSettings}
        categories={categories.edges}
        pathPrefix={"/projects/"}
      />
      {layout &&
        layout.map((item, index) => {
          if (!item.__typename) return null;
          const layoutComponentName = item.__typename.replace(
            "WordPressAcf_",
            ""
          );
          if (item.__typename !== "WordPressAcf_TopContentAndImage")
            return (
              <AcfComponent
                key={index}
                index={index}
                componentName={layoutComponentName}
                item={item}
                location={location}
                pageContext={pageContext}
              />
            );
        })}
    </Layout>
  );
};

export default ProjectIndexPage;

export const pageQuery = graphql`
  query ProjectIndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions {
      options {
        customCss
      }
    }
    wordpressPost: wordpressPage(slug: { eq: "projects" }) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
      acf {
        layout: layout_page {
          __typename
          ...TopContentAndImageQuery
          ...ContactBlockQuery
          ...ImageSliderQuery
        }
      }
    }
    wordpressCategory: wordpressWpProjectCategory(
      slug: { eq: "uncategorised" }
    ) {
      name
      slug
      path
      description
      acf {
        featureImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 670, quality: 70) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        title
      }
    }
    allWordpressWpProjects(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
        }
      }
    }
  }
`;
